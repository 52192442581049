.contact-form-v1 {
	padding-bottom: 62px !important;
	display: flex;
	height: 100%;

	.contact-form {
		width: 100%;
	}

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}