.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		/*border: 1px solid var(--color-grey-normal);*/
		border-radius: 0;

		.card-header {
			/*background-color: var(--faq-title-bg-color);*/
			background-color: transparent;
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	.btn-link[aria-expanded="false"]:before {
		content: "\f107";
		font-family: 'Font Awesome 5 Free';
		position: absolute;
		right: 14px;
		color: #60B3E6;
		font-size: 25px;
		top: 5px;
	}

	.btn-link[aria-expanded="true"]:before {
		content: "\f106";
		font-family: 'Font Awesome 5 Free';
		position: absolute;
		right: 14px;
		color: #60B3E6;
		font-size: 25px;
		top: 0;
	}

}