.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	/*@media(max-width:1199px) {
		margin-top: 90px;
	}*/

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 0;
		}
	}

	.unit-banner-img-1 {
		width: 100%;
		padding-top: 50px;
		margin-bottom: 40px;
		display: block;

		@media (max-width:992px) {
			padding-top: 30px;
		}

		img {
			width: 100%;
			height: 500px;
			object-fit: cover;

			@media (max-width: 767px) {
				height: 350px;
			}

			@media (max-width: 580px) {
				height: 300px;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}