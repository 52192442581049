.urv-plan {
	border: solid 1px #3193c9;
	margin-bottom: 4px;
	padding: 6px;
	width: 100%;
	display: table;
	cursor: pointer;
}

.urv-check {
	display: table-cell;
	width: 20px;
	padding-left: 5px;
}

.urv-content {
	display: table-cell;
	padding: 5px;

	.btn {
		margin: 5px;
		min-width: 200px;
	}
}

.urv-amount {
	display: table-cell;
	vertical-align: middle;
	width: 20px;
	padding: 5px;
}

.urv-content .urv-plan-amount {
	display: inline;
	float: right;
}

.travelInsuranceSelected {
	background-color: #3193c9;
	color: #FFFFFF;
}

.travelInsuranceSelected a {
	color: #FFFFFF;
}

.urv-logo {
	text-align: right;
	height: 55px;

	img {
		width: 125px;
		float: right;
	}
}

.urv-table {
	width: 100%;
	font-size: 14px;
}

.urv-detail-content {

	.urv-name {
		font-size: 15px;
		font-weight: 700;
		padding-bottom: 10px;
	}

	a {
		padding-top: 10px;
		text-decoration: underline;
		color: var(--font-color-main);

	}

	.urv-hint {
		padding-top: 10px;
	}

	.urv-detail-amount {
		text-align: right;
		border-top: 1px solid #cccc;
		padding-top: 10px;
		margin-top: 10px;
		font-weight: 700;
	}
}

.urv-testmode {
	background-color: #C00000;
	color: #FFFF00;
	text-align: center;
	padding: 3px;
	marigin-bottom: 2px;
	display: none;
}

.urv-result-button {
	text-align: center;
}

.urv-hint {
	margin: 15px 0px;
	font-weight: 700;
	color: #0c699d;
	background-color: #f7f4df;
	padding: 10px;
}

.showInsuranceBookingHint {
	margin-top: 20px;

	a {
		color: #0c5460 !important;
		text-decoration: underline;
	}

	.fa-check {
		color: green;
	}

	.hint {
		margin-bottom: 10px;
	}

	.link {
		padding-bottom: 10px;
	}

	.missing {
		background-color: white;
		border: 1px dashed red;
		padding: 2px;
	}

	.urvlogo {
		display: none;

		@media (min-width:1200px) {
			float: right;
			display: block;
		}

		img {
			width: 128px;
		}
	}
}

.showInsuranceButtonHint {
	margin-top: 20px;
}