.searchbar-v1 {
	position: absolute;
	top: 65vh;
	width: 100%;

	@media (max-width: 992px) {
		top: 49vh;
	}

	@media (max-width: 992px) {
		top: 44vh;
	}

	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	@media (max-height: 600px) {
		top: 350px;

	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 0;
		flex-flow: wrap;
		border-radius: 5px;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/
		box-shadow: 0 0 20px rgb(0 0 0 / 25%);


		.form-flex {
			width: 21%;
			padding: 8px 22px;
			border-right: 2px solid #e1e1e1;
			margin: 6px 0;

			&:nth-child(4) {
				border-right: 0;
			}

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			@media (max-width:767px) {
				padding: 6px 12px;
				margin: 0;
			}

			.fa {
				position: absolute;
				left: 0;
				top: 10px;

				&.fa-map-icon {
					background: #fff url(RESOURCE/img/map_icon.svg) no-repeat 0 0;
					background-size: contain;
					width: 25px;
					height: 20px;

					&:before {
						content: "" !important;
					}
				}

				&.fa-date {
					background: #fff url(RESOURCE/img/icon-calendar.svg) no-repeat 0 0;
					background-size: contain;
					width: 25px;
					height: 20px;

					&:before {
						content: "" !important;
					}
				}

				&.users-icon {
					background: #fff url(RESOURCE/img/icon-user-friends.svg) no-repeat 0 0;
					background-size: contain;
					width: 25px;
					height: 20px;

					&:before {
						content: "" !important;
					}
				}
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				padding-left: 0;
				height: auto;
				min-height: 40px;
				padding-left: 30px !important;

				@media (max-width:767px) {
					font-size: 14px;
				}
			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;
				margin: 0;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;

				.fa {
					color: #c6c6c6;
				}
			}

		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 8%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 0;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 101%;
				height: 70px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;
				border-radius: 0 5px 5px 0;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}