.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);
	height: 120px;


	@media (max-width:1200px) {
		position: absolute;
	}

	@media (max-width:767px) {
		height: 90px;
	}



	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}

		.fav-count {

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: -5px;
				left: 2px;
				width: 18px;
				height: 18px;
				background: var(--color-primary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;
			}
		}

	}

	.header-main {

		.header-logo {
			position: absolute;
			top: 15px;
			z-index: 20;

			.logo {
				width: 150px;
				height: auto;

				@media (max-width:1200px) {
					width: 283px;
				}

				@media (max-width:768px) {
					width: 200px;
				}
			}

			img {
				width: 150px;

				@media (max-width:992px) {
					width: 130px;
				}

				@media (max-width:767px) {
					width: 110px;
				}


				&.logo-blue {
					display: block;
				}

				&.logo-white {
					display: none;
				}
			}

		}

		.main-menu {
			float: right;
			margin-top: 40px;

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 120px;
				margin-top: 0;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			@media (max-width:767px) {
				top: 90px;
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 20px;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media all and (min-width: 993px) and (max-width:1199px) {
							font-size: 14px;
							padding-left: 11px;
							padding-right: 11px;
						}

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}
					}

					&:hover {
						>a {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;

						}

						.dropdown-toggle {
							color: var(--main-nav-font-color-hover);
						}

						@media (min-width:1200px) {
							.dropdown-menu {
								display: block;
							}
						}

						.separator-line {
							color: #fff !important;
						}

					}


					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}

					.dropdown-toggle.separator-line {
						color: var(--main-nav-font-color);
						position: absolute;
						top: 12px;
						right: 8px;

						@media all and (min-width: 993px) and (max-width: 1199px) {
							right: 0;
						}

						@media (max-width:992px) {
							color: #fff;
							right: 10px;
							top: 3px;
							font-size: 20px;
							min-height: 25px;
						}

						&.full-arrow {
							@media (max-width:992px) {
								width: 100%;
								text-align: right;
							}
						}

						/*&.separator-line {
							border-left: 1px solid #fff;
							height: 100%;
							top: 0;
							bottom: 0;
							line-height: 48px;
							padding-left: 5px;
							right: 4px;

							@media (max-width:1200px) {
								line-height: 25px;
								height: 25px;
								top: auto;
								padding-left: 10px;
							}

							@media (max-width:767px) {
								border-left: 0;
							}
						}*/
					}



				}
			}

			.btn {
				background: transparent;
				border: 0;
				color: #60b3e6;
				padding: 10px 20px;
				box-shadow: none !important;

				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}


			}



			.header-social-icon {
				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;

					li {
						padding: 10px;

						.btn {
							padding: 0;
						}
					}
				}
			}

			.search-unit-ul,
			.search-col {
				@media (max-width:767px) {
					display: none;
				}

			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

		@media (max-width:767px) {
			top: 28px !important;
		}
	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	@media (max-width:767px) {
		.navbar-v1 .icon {}

		.main-menu {
			top: 28px;
		}
	}

	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;

		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			top: 40px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
			box-shadow: none;
			padding: 0;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;

			.search-col {
				display: none !important;
			}
		}

		.search-unit-ul {
			margin: 15px 0px 0px;
			display: none;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				top: 15px !important;
				/*position: static;
				text-align: center;*/

			}

			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				bottom: 30px;
				right: 10px;
			}
		}

	}



	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		/*background: transparent;
		box-shadow: none;
		z-index: 4;*/

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}




		.header-main {

			.header-logo {

				.logo-white {

					display: block;
				}

				.logo-blue {
					display: none;

				}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							/* color: var(--main-nav-font-color-affix-top); */

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}

		&:before {
			/*content: " ";
			background: rgb(0, 0, 0);
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
			width: 100%;
			position: absolute;
			height: 290px;
			top: 0;
			z-index: -1;
			display: block !important;

			@media (max-width: 1199px) {
				height: 240px;
			}

			@media (max-width: 767px) {
				height: 160px;
			}*/
		}

		.menu-toggle {


			span {
				background-color: #ffffff;

				&:before,
				&:after {
					content: "";
					position: absolute;
					display: block;
					height: 4px;
					width: 35px;
					background-color: #ffffff;

				}
			}
		}

	}

	.main-navbar {
		position: fixed;
		right: 0;
		height: 100vh;
		width: 350px;
		top: 0;
		background: var(--color-primary);
		padding: 100px 0 25px 0;
		transition: all .35s;
		transform: translateX(100%);
		z-index: 99;

		.submenu {
			display: none;
			background: #429cd3;

			ul {
				padding: 10px 0;

				li {
					padding: 0;

					a {
						font-size: 18px;
						color: #fff !important;
						color: var(--color-white) !important;
						padding: 5px 25px;
					}

				}
			}
		}

		.dropdown-toggle {
			&.slidedown {
				background: #31759f;
				text-decoration: none;
			}
		}

		ul {
			li {

				a {
					padding: 10px 25px;
					display: inline-block;
					width: 100%;
					text-decoration: none;
					cursor: pointer;
					color: var(--color-white);
					font-size: 22px;

				}

				a[aria-expanded="true"] {
					background: #31759f;
					text-decoration: none;
				}
			}
		}

		ul {
			.dropdown-menu {
				padding: 0;
				border-radius: 0;
				border: none;
				box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
				min-width: 300px;
				margin-top: 0;
				position: relative !important;
				background: transparent;
				box-shadow: none;
				transform: none !important;
				background: #429cd3;
				width: 100%;

				.sub-nav {
					padding: 10px 0;
				}

				li {
					padding: 0;

					a {
						font-size: 18px;
						color: var(--color-white) !important;
						padding: 5px 25px;
					}
				}

				ul {
					padding-left: 0;
				}
			}
		}

		.menuclose {
			position: absolute;
			top: 15px;
			right: 25px;
			font-size: 35px;
			cursor: pointer;
			color: var(--color-white);
		}
	}

	.menu-toggle {
		width: 50px;
		height: 100px;
		position: relative;
		right: 0;
		display: flex;
		float: right;

		a {
			position: absolute;
			top: 25px;
			height: 50px;
			width: 50px;
			display: flex;
			align-items: center;
		}

		@media (max-width:992px) {
			top: 0;
		}

		span {
			background-color: #60b3e6;
			height: 4px;
			width: 35px;
			position: absolute;

			&:before {
				top: 14px;
				left: 0px;
			}

			&:after {
				top: -14px;
				left: 0px;

			}

			&:before,
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 4px;
				width: 35px;
				background-color: #60b3e6;

			}
		}
	}

	.main-navbar {
		.menu-logo {
			position: absolute;
			top: 20px;
			left: 20px;

			img {
				width: 100px;
			}
		}
	}

	&.openMenu {

		.main-navbar {
			transform: translateX(0);
		}
	}


}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}

.cta-bl {
	display: flex;
	position: absolute;
	right: 60px;
	top: 50px;
	margin: 0;

	@media (max-width:767px) {
		top: 35px;
	}

	.btn {
		background: transparent;
		border: 0;
		color: #60b3e6;
		padding: 0;


		&:hover {
			background: transparent;
			color: #60b3e6;
		}
	}

	ul {
		display: flex;
		align-items: center;
		margin: 0;

		li {
			margin: 0 10px;
		}
	}


}

.search-col {
	display: none;
	width: 350px;
	position: absolute;
	right: -90px;
	background: #60b3e6;
	padding: 10px;
	top: 120px;

	@media (max-width:1199px) {
		right: -50px;
		top: 70px;
	}

	@media (max-width:767px) {
		top: 55px;
	}

	.form-control {
		border: 0;
		border-radius: 0;
	}

	.btn {
		padding: 0 15px !important;
		color: #fff !important;
	}
}